
import { defineComponent } from "vue";
import navVue from "@/components/nav.vue";
import router from "@/router";

export default defineComponent({
  name: "HomeView",
  components: {
    navVue,
  },
});
