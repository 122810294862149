import { createStore } from "vuex";
export type State = {
  count: number;
};

export const store = createStore({
  state: {
   
  },
  getters: {},
  mutations: {
    
  },

  actions: {
   
  },
  modules: {},
});
