<template>
  <router-view />
</template>

<style lang="scss">
  @import url("https://fonts.googleapis.com/css2?family=Italiana&family=Kaisei+HarunoUmi:wght@400;500;700&family=Kulim+Park:wght@300;400&display=swap");

  @import url('https://fonts.googleapis.com/css2?family=Katibeh&display=swap');

  @font-face {
    font-family: 'Stanley';
    src: url('@/assets/fonts/Stanley.otf') format('truetype');
  }

  :root {
    --bg-color: #ffffff;
    --secondary-bg-color: #ffffff;
    --text-color: #000000;
    --accent-color: #C1B8B5;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }



  #app {
    font-family: "Italiana", serif;
    font-style: normal;
  }
</style>
