
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavBar",
  computed: {
    activeRoute() {
      return this.$route.path;
    },
  },
  setup() {
    return {};
  },
});
