import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import { MotionPlugin } from "@vueuse/motion";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyClYp8S91ZwdWExSvVc53gwjtSif3RoqAI",
  authDomain: "evelyn-photo.firebaseapp.com",
  projectId: "evelyn-photo",
  storageBucket: "evelyn-photo.appspot.com",
  messagingSenderId: "868183907457",
  appId: "1:868183907457:web:5e5f404a3978278d838180",
  measurementId: "G-SGC5P5NWL7",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);

let vue_app: any;

onAuthStateChanged(auth, () => {
  if (!vue_app) {
    vue_app = createApp(App)
      .use(store)
      .use(MotionPlugin)
      .use(router)
      .mount("#app");
  }
});

export { app, db, analytics, functions, auth };
